// 修改,新增,查看弹窗的封装
<template>
  <WrapModal
    v-model:visible="props.visible"
    formMode
    tooSmall
    :width="props.modaltype === 'view' ? 658 : 600"
    :modealTitle="props.modaltitle"
    @ok="onOk"
    @cancel="onCancel"
    :confirmLoading="confirmLoading"
    :footer="props.modaltype === 'view' ? '' : on"
  >
    <template v-if="props.modaltype !== 'view'">
      <a-form ref="formRef" :rules="rules" :model="formData" class="file-type">
        <a-form-item label="文件类型名称" name="typeName">
          <a-input
            :maxlength="60"
            placeholder="请输入文件类型名称"
            v-model:value="formData.typeName"
            style="width: 432px;"
          />
        </a-form-item>
        <a-form-item label="文件类型编号" name="typeNumber" class="form-item">
          <a-input
            :maxlength="60"
            placeholder="请输入文件类型编号"
            v-model:value="formData.typeNumber"
            style="width: 432px;"
          />
        </a-form-item>
        <a-form-item label="说明" class="form-textarea">
          <a-textarea
            v-model:value="formData.explain"
            style="width: 432px;height:140px"
            :maxlength="500"
            placeholder="请输入说明"
          />
        </a-form-item>
      </a-form>
    </template>
    <template v-if="props.modaltype === 'view'">
      <p v-if="formData.processId" style="height:30px"><LinkOutlined />已绑定归档流程</p>
      <section class="content show-scrollbar">
        <section>
          <a-descriptions
            title=""
            layout="vertical"
            bordered
            :column="2"
            size="small"
            class="descript"
            style="border-top: 1px solid #f0f0f0"
          >
            <a-descriptions-item label="文件类型名称" style="vertical-align: top;">
              {{ formData.typeName }}
            </a-descriptions-item>
            <a-descriptions-item label="文件类型编号">
              {{ formData.typeNumber }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="" layout="vertical" bordered :column="1" size="small" class="descript">
            <a-descriptions-item label="说明" style="vertical-align: top;">
              {{ formData.explain || '-' }}
            </a-descriptions-item>
          </a-descriptions>
        </section>
      </section>
    </template>
  </WrapModal>
</template>
<script setup>
import { ref, reactive, defineProps, onUpdated, toRefs, onMounted } from 'vue'
const props = defineProps(['visible', 'modalVisible', 'modaltitle', 'modaltype', 'jumpData'])
import WrapModal from '@/components/WrapModal/index'
import { addTypeList, setTypeList } from '@/apis/businessManage'
import { LinkOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
// const emit=defineEmits(['changeVisible'])
const formData = reactive({
  typeName: '',
  typeNumber: '',
  explain: ''
})
const formRef = ref()
//提交按钮是否加载
let confirmLoading = ref(false)
onMounted(() => {
  if (props.modaltype !== 'add') {
    formData.id = props.jumpData.id
    formData.typeName = props.jumpData.typeName
    formData.typeNumber = props.jumpData.typeNumber
    formData.explain = props.jumpData.explain
    formData.processId = props.jumpData.processId
    console.log('666666666666677777777777', props.jumpData)
  } else {
    generateRandomDate()
  }
})

//关闭
const onCancel = () => {
  // emit('changeVisible')
  console.log('关闭！！')
  //重置formData内容
  formData.id = ''
  formData.typeName = ''
  formData.typeNumber = ''
  formData.explain = ''
  props.modalVisible()
}
//文档编号年月日+随机8位数
const generateRandomDate = () => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')
  const randomNum = String(Math.floor(Math.random() * 100000000)).padStart(8, '0')
  formData.typeNumber = `${year}${month}${day}${randomNum}`
}
//表单验证
const rules = {
  typeName: [
    {
      required: true,
      message: '请输入文件类型名称'
    },
    {
      validator: (rule, value, callback) => {
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/
        if (emojiRegex.test(value)) {
          callback(new Error('文件类型名称不支持emoji表情包'))
        } else {
          callback()
        }
      },
      trigger: 'change'
    },
    {
      validator: (rule, value, callback) => {
        if (value && value.includes(' ')) {
          callback(new Error('文件类型名称不允许包含空格'))
        } else {
          callback()
        }
      },
      trigger: 'change'
    }
  ],
  typeNumber: [
    {
      required: true,
      message: '请输入文件类型编号'
    },
    {
      pattern: '^[a-zA-Z0-9《》/！“”（）@#$%^&*()_+-=/*-+【】<>/!""()\\[\\]]+$',
      message: '文件类型编号仅支持输入英文数字和特殊字符',
      trigger: 'change'
    }
  ]
}
//提交
const onOk = async () => {
  formRef.value
    .validate()
    .then(() => modalSubmit())
    .catch(() => {
      confirmLoading.value = false
    })
}
const modalSubmit = async () => {
  ;(confirmLoading.value = true), console.log('表单内容', formData)
  //提交表单数据
  if (props.modaltype === 'add') {
    addTypeList(formData).then(res => {
      if (res.success) {
        confirmLoading.value = false
        cmsNotice('success', '文件类型添加成功')
        onCancel()
      } else {
        confirmLoading.value = false
      }
    })
  } else if (props.modaltype === 'set') {
    setTypeList(formData).then(res => {
      if (res.success) {
        confirmLoading.value = false
        cmsNotice('success', '文件类型修改成功')
        onCancel()
      } else {
        confirmLoading.value = false
      }
    })
  }
}
</script>
<style lang="less" scoped>
.file-type {
  padding-right: 6px;
}
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;

  &:hover {
    border-color: transparent;
  }
}

.view-table-header {
  display: flex;
  margin-top: 10px;

  .table-title {
    height: 36px;
    width: 306px;
    line-height: 36px;
    background: #fafafa;
    color: gray;
    padding-left: 12px;
    border: 1px solid #e6e6e6;
  }
}

.view-table-content {
  display: flex;

  .table-content {
    width: 306px;
    border: 1px solid #e6e6e6;
    padding: 12px;
    min-height: 58px;
  }
}

.view-table-explain {
  padding: 12px;
  background: #fafafa;
  color: gray;
  border: 1px solid #e6e6e6;
}

.table-explain {
  width: 610px;
  min-height: 102px;
  padding: 12px;
  border: 1px solid #e6e6e6;
}
.content {
  // padding: 24px 24px;
  max-height: 519px;
  overflow-y: auto;

  :deep(.ant-descriptions-item-label) {
    color: rgba(0, 0, 0, 0.45);
  }

  :deep(.ant-descriptions-item-content) {
    color: rgba(0, 0, 0, 0.65);
  }

  .mark {
    > p {
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      margin-bottom: 8px;
    }

    :deep(.ant-input-textarea-show-count::after) {
      top: -25px;
    }
  }

  .descript {
    :deep(.ant-descriptions-item-label) {
      width: 50%;
    }
  }

  :deep(.ant-descriptions-view:nth-of-type(1)) {
    border-top: none;
  }
}
.form-textarea {
  :deep(textarea.ant-input) {
    max-width: 432px;
  }
}
.file-type {
  :deep(.ant-form-item-control) {
    margin-left: 0px !important;
    width: 100% !important;
    line-height: 32px !important;
    flex: none !important;
  }
}
</style>
